import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Events } from '@ionic/angular';
import { WeeklyService } from './services/weekly.service';
import {S3imagesService} from './services/s3images.service'
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { firebase } from '@firebase/app';
import { environment } from '../environments/environment';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  rootPage: any;
  logoutres: Observable<any>;
  AccountType: string;
  username: any;
  toast: any;
  routerStr: string;
  appPages = [];
  navLinksArray = [];// store route links as the user navigates the app
  loginId: any;
  weekly: Observable<any>;
  headerres: any;
  currency: any = "";
  loaderToShow: Promise<void>;
  // ==================================================for web push notification===============================================================================
  async ngOnInit() {
    firebase.initializeApp(environment.firebase);
    await this.apiService.init();
  }
  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      await this.apiService.requestPermission();
    });
  }
  // =======================================================for web push notification==========================================================================
  constructor(private apiService: WeeklyService, public s3Service:S3imagesService, public toastController: ToastController, private platform: Platform,
    private splashScreen: SplashScreen, private statusBar: StatusBar, public storage: Storage, private router: Router,
    public events: Events, private fcm: FCM, private alertController: AlertController, private localNotifications: LocalNotifications) {
   this.routerStr=router.url;
   console.log("222","~~~routerStr~~"+this.routerStr)
    events.subscribe('user:loggedin', () => {
      this.startMyThread().then(truthy => {
        this.storage.get('loginUsername').then((val) => {
          console.log("RRRRRRRRRRRRRRRRRRRR");
          console.log(val);
          if (val == null || val == "Guest") {
            this.username = "Guest";
            apiService.setIsGuest('true');
            this.appPages = [{ title: 'Home' },
            {title: 'Shop by Groceries'},
            {title: 'Weekly Deals'},
            {title: 'Customer Service'},
        
            {title: 'Login'}];
          } else {
            this.username = val;
            apiService.setIsGuest('false');
            this.appPages = [{ title: 'Home' },
            { title: 'Shop by Groceries' },
    
            { title: 'Weekly Deals' },
            { title: 'Do It Rewards Card' },
            { title: 'Customer Service' },
   
            { title: 'Order History' },
            { title: 'Reorder List'},
            { title: 'Notification' },
            { title: 'Gift Card' },
            { title: 'Feedback' },
            { title: 'Logout' }];
          }
          this.storage.get('CardNo').then((val) => {
            apiService.setStorageCardNo(val);
            this.storage.get('Password').then((val1) => {
              apiService.setStoragePassword(val1);
              this.storage.get('AccountType').then((val) => {
                this.AccountType = val;
                this.initializeApp();
              });
            });
          });
        });
      });
    });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getFirebaseConfigure();
    });
  }
  getFirebaseConfigure() {
    this.getFireBaseToken();
    this.platform.ready().then(() => {
      try {
        this.fcm.onNotification().subscribe(data => {
          console.log(data.wasTapped + "~~~~~~~~~~notificationdata~~~~~~~~~~~~~~" + JSON.stringify(data));
          if (data.wasTapped) {
            this.startMyThread().then(truthy => {
              this.router.navigate(['notification']);
            });
          }
          else {
            let noti_img = data.image;

            let msg = "<ion-img style=" + "\"" + "width:auto;" + "\"" + "src= " + "\"" + noti_img + "\"" + " ></ion-img>";

            if (this.platform.is('android')) {
              this.presentAlert(data.title, data.body, msg);
            } else {
              this.presentAlert(data.aps.alert.title, data.aps.alert.body, msg);
            }
          }
        });
      } catch (err) {
        alert("Error occur")
      }
      this.fcm.onTokenRefresh().subscribe(token => {
        console.log("~~~~~~~~~~~~~~tokenrefresh~~~~~~~~~~~~~~~~" + token);
        this.apiService.setFirebaseToken(token);
      });
    });
  }
  getFireBaseToken() {
    try {
      this.fcm.getToken().then(token => {
        console.log("~~~~~~~~~~~~~~token~~~~~~~~~~~~~~~~~" + token);
        this.apiService.setFirebaseToken(token);
      }, function (error) {
        console.log('Error!!!' + error);
      });
    } catch (e) {
      alert(JSON.stringify(e));
    }
  }
  async presentAlert(msg: any, submsg: any, message: string) {
    const alert = await this.alertController.create({
      header: msg,
      subHeader: submsg,
      message: message, //'<ion-img style="width: auto;" src= "" ></ion-img>',
      buttons: [
        {
          text: 'ok',
          handler: data => {
            this.startMyThread().then(truthy => {
              this.router.navigate(['notification']);
            });
          }
        }
      ]
    });
    await alert.present();
  }
  sendLocalNotification(data: any) {
    let title = data.title;
    let body = data.body;
    let img = data.icon;
    this.localNotifications.schedule({
      id: 1,
      text: body,
      title: title,
      smallIcon: img,
      icon: img
    });
    this.localNotifications.on('click').subscribe(notification => {
      this.startMyThread().then(truthy => {
        this.router.navigate(['notification']);
      });
    });
  }
  showToast(msg) {
    this.toast = this.toastController.create({
      message: msg,
      duration: 2000
    }).then((toastData) => {
      toastData.present();
    });
  }
  HideToast() {
    this.toast = this.toastController.dismiss();
  }
  menuItemClick(itemname) {
    if (itemname == 'Home') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['home']);
      });
    } else if (itemname == 'Weekly Deals') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['weekly-special', { whichWeekly: "" }]);
      });
    } else if (itemname == 'Customer Service') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['customer-services']);
      });
    } else if (itemname == 'Shop by Groceries') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['category']);
      });
    
    }else if (itemname == 'Order History') {
      this.getOrderHistoryCount();
    } else if (itemname == 'Notification') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['notification']);
      });
    } else if (itemname == 'Reorder List') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['wishlist']);
      });
    } else if (itemname == 'Feedback') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['feedback']);
      });
    } else if (itemname == 'Gift Card') {
      this.startMyThread().then(truthy => {
        this.router.navigate(['giftcard']);
      });
    } else if (itemname == 'Do It Rewards Card') {
      this.startMyThread().then(truthy => {
        if (this.AccountType == "Credit") {
          this.router.navigate(['own-card']);
        } else {
          this.router.navigate(['card']);
        }
      });
    } else if (itemname == 'Logout' || itemname == 'Login') {
      if (itemname == 'Login') {
        this.storage.clear();
        this.startMyThread().then(truthy => {
          this.router.navigate(['login'], { replaceUrl: true });
        });
      } else if (itemname == 'Logout') {
        this.callLogoutApi();
      }
    }
  }
  getOrderHistoryCount() {
    var cardno = this.apiService.getStorageCardNo();
    var header = this.apiService.getStorageHeader();
    var password = this.apiService.getStoragePassword();
    this.logoutres = this.apiService.getAllOrderHistory(cardno, header, password);
    this.logoutres.subscribe(data => {
      if (data.status == "false") {
        this.showToast(data.msg);
      } else if (data.status == "true") {
        this.startMyThread().then(truthy => {
          this.router.navigate(['orderhistory']);
        });
      }
    });
  }
  callLogoutApi() {
    this.showToast("Logout Successfully");
    this.storage.clear();
    this.startMyThread().then(truthy => {
      this.router.navigate(['login'], { replaceUrl: true });
    });
  }
  startMyThread() {
    return new Promise((resolve, reject) => {
      setTimeout(() => { resolve(true); }, 10);
    });
  }
}
