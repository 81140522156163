import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { RequestOptions, Request, RequestMethod, Headers } from "@angular/http";
import { Http } from "@angular/http";
import "rxjs/add/operator/map";
import { firebase } from "@firebase/app";
import "@firebase/messaging";
import { environment } from "../../environments/environment";
import { utf8Encode } from "@angular/compiler/src/util";
import { Events, Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import {S3imagesService} from './s3images.service';

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class WeeklyService {
  FirstHeadingWeekly: string;
  LoginCardNo: string;
  LoginPassword: string;
  LoginHeader: string;
  CartCount: any;
  isGuest: any;
  Currency: string;
  FirebaseToken: string;
  films: Observable<any>;
  login: Observable<any>;
  isLoginGoBack: boolean = false;
  searchPlaceHolder: String = "What are you looking for ?";
  conditionApp: boolean = true;
  headerres: Observable<any>;

  // url = 'http://sha-phdpmnbbcn.dynamic-m.com/doitweb/doitcenter.asmx/'
  // url = 'http://localhost:50480/DoItCenter.asmx/'
  // url = 'http://192.168.1.229/doitweb/doitcenter.asmx/' //local site
  //url ='http://sha-phdpmnbbcn.dynamic-m.com/doitcenterwebsite/doitcenter.asmx/' //live till 20/10/21
    // url = "https://loyalty.doit.aw:8080/doitcenterwebsite/doitcenter.asmx/"; //original url for live site
  // url='https://dashboard.doit.aw:8081/doitcenterwebsite/doitcenter.asmx/'
  // url='https://adarshtated.com/dev/spacenter/api/'
    //  url ='http://192.168.1.71/doitwebsiteapi/doitcenter.asmx/'
    //priyanka has commented this
    url= 'https://loyalty.doit.aw:8082/doitcenterwebsite/doitcenter.asmx/'
    // url='http://sha-phdpmnbbcn.dynamic-m.com/doit_latest/doitcenter.asmx/'
    // url='http://192.168.1.229/doitwebsiteapi/doitcenter.asmx/'
        // url='http://192.168.1.229/checkwebservicedoit/doitcenter.asmx/'

  
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('WEBAPI:SSjvDC9FijvuYLlEp6w4UzHb9VTOAjCA+DWeHTcocjw=')
    })
  };
  imageUrl = this.S3images.s3url;
  // imageUrl = "https://d2s3cc6x0yl03x.cloudfront.net/ProductImages/";
  noImageUrl ="https://d2s3cc6x0yl03x.cloudfront.net/ProductImages/no_image.jpg";
  imageFormat = ".jpg";

  dynamicurl = "http://sha-phdpmnbbcn.dynamic-m.com:9048/APP/ODataV4/Company('Doit Center')/";
  constructor(
    private http: HttpClient,
    public storage: Storage,
    public platform: Platform,
    private events: Events,
    private  S3images: S3imagesService,
  ) {
    
    console.log("@@@@@@@@@@@@@@@@@new live url@@@@@@@@@@@@@@@")
    console.log("~~~~~service constructor~~~~~~" + this.imageUrl);

    //this.storage.set('loginUsername', "Guest");
    this.storage.set("Header", "vugwI876543");
    this.storage.set("CardNo", "1");
    this.setIsGuest("true");
    this.setStorageCardNo("1");
    this.setStoragePassword("1");
    this.setStorageHeader("vugwI876543");

    this.getHeaderRes();
  }

  mydata: MyDataType[];
  dryGroceryMain: any;
  dairyMain: any;
  Price:string ;
  top10WishListdata: any;
  top10WishListOrederdata: any;

  getUsersForSubscription(
    groupname: string,
    CardNo: string,
    header: string,
    Password: string,
    pageno: string
  ): Observable<MyDataType[]> {
    this.dryGroceryMain = this.http.get(
      this.url +
        "getSubGroup?group=" +
        groupname +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.dryGroceryMain;
  }

  getCatDataWithObservable
  (
    CardNo: string,
    header: string,
    Password: string
  ): Observable<CatDataType[]> {
    this.films = this.http.get(
      this.url +
        "getGroup?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    console.log(
      "~~~~~~getCatDataTypeWishlistWithObservable~~~~~~~" +
        this.url +
        "getCatDataTypeWishlistWithObservable?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    console.log(
      "~~~~~~getCatDataTypeWishlistWithObservable~~~~~~~" +
        this.url +
        "getCatDataTypeWishlistWithObservable?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    return this.films;
  }

  getTop10UserWishlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{ data: Top10Data[] }> {
    this.top10WishListdata = this.http.get(
      this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    console.log(
      "~~~~~~getTop10UserWishlist~~~~~~~" +
        this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        Top10Data
    );
    console.log(
      "~~~~~~getTop10UserWishlist~~~~~~~" +
        this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    return this.top10WishListdata;
  }

  getTop10UserOrderedlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{ data: Top10Data[] }> {
    this.top10WishListOrederdata = this.http.get(
      this.url +
        "Top10UserOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.top10WishListOrederdata;
  }

  getTop10UserProcessedOrderedlistWithObservable(
    CardNo: string,
    header: string,
    Password: string
  ): Observable<{ data: Top10Data[] }> {
    this.top10WishListOrederdata = this.http.get(
      this.url +
        "Top10UserProcessedOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.top10WishListOrederdata;
  }

  //  ===========================load basic data=========================================

  getHeaderRes() {
    this.headerres = this.getHeader();
    this.headerres.subscribe((data) => {
      console.log("~~~headerres~~~~" + JSON.stringify(data));
      if (data.status == "false") {
      } else if (data.status == "true") {
        this.setStorageHeader(data.header);
        this.doneClick();
        this.events.publish("user:loggedin");
      }
    });
  }

  doneClick() {
    let currency = "fls";
    this.setCurrency(currency);

    this.storage.get("loginId").then((val) => {
      let loginId = val;

      if (loginId == null) {
        this.getGuestLogin();
      } else {
        this.openHome();
      }
    });
  }

  getGuestLogin() {
    // this.storage.set('loginUsername', "Guest");
    // this.storage.set('Header',"vugwI876543");
    this.storage.set("CardNo", "1");

    this.setIsGuest("true");

    this.setStorageCardNo("1");

    this.setCurrency("");
    this.openHome();
  }

  openHome() {
    console.log("~service~~~StorageHeader~~" + this.getStorageHeader());
    console.log("~service~~~StorageCardNo~~" + this.getStorageCardNo());
    console.log("~service~~~StoragePassword~~" + this.getStoragePassword());

    // this.startMyThread().then(truthy => {
    //   this.router.navigate(['home'], { replaceUrl: true });
    // });
  }

  
  // ====================================================All api with microsoft dynamic server==================================================================
  getProductListDynamic(skip, next, subgroupcode) {
    this.films = this.http.get(this.dynamicurl + "AppItemList?$filter=LSC_Retail_Product_Code eq '" +
      subgroupcode + "' &$skip=" + skip + "&$top=" + next, this.httpOptions);
    console.log(this.dynamicurl + "AppItemList?$filter=LSC_Retail_Product_Code eq '" +
      subgroupcode + "' &$skip=" + skip + "&$top=" + next);
    return this.films;
  }

  getProductImage(imagecode) {
    this.films = this.http.get(this.dynamicurl + "AppRetailImage?$filter=Code eq '" +
      imagecode + "' ", this.httpOptions);
    return this.films;
  }

  getMemberDetail(cardno) {
    this.films = this.http.get(this.dynamicurl + "AppMemberContact?$filter=Account_No eq '" +
      cardno + "' ", this.httpOptions);
    return this.films;
  }

  getSearchDynamic(skip, next, searchkey) {
    this.films = this.http.get(this.dynamicurl + "AppItemList?$filter="+
    searchkey+"&$skip=" + skip + "&$top=" + next, this.httpOptions);


    console.log(this.dynamicurl + "AppItemList?$filter="+
    searchkey+"&$skip=" + skip + "&$top=" + next);
    return this.films;
  }

  getGroupDynamic() {
    this.films = this.http.get(this.dynamicurl + "AppCategory?$filter=LSC_Division_Code eq 'GROC' and Description ne 'Alcohol'", this.httpOptions);
    console.log(this.dynamicurl + "AppCategory?$filter=LSC_Division_Code eq 'GROC' and Description ne 'Alcohol'");
    return this.films;
  }
  getSubGroupDynamic(skip, next, categoryid) {
    this.films = this.http.get(this.dynamicurl + "AppProductGroup?$filter=Item_Category_Code eq '" + categoryid + "'", this.httpOptions);

    return this.films;
  }

  // ====================================================/All api with microsoft dynamic server==================================================================
 

  // ================================load basic data======================================

  // ===================================================All api with security header===================================================================

  getHeader() {
    this.films = this.http.get(this.url + "getHeader");
    return this.films;
  }

  getLogin(
    CardNo: string,
    pass: string,
    header: string,
    firebasetoken: string
  ) {
    this.films = this.http.get(
      this.url +
        "Login?Cardno=" +
        CardNo +
        "&Password=" +
        pass +
        "&Header=" +
        header +
        "&FirebaseToken=" +
        firebasetoken
    );
    return this.films;
  }

  fetchWeeklySpecialData(
    CardNo: string,
    header: string,
    category: string,
    Password: string,
    pageno: string
  ) {
    category = encodeURIComponent(category);
    console.log("~~~category~~~" + category);
    this.films = this.http.get(
      this.url +
        "FetchWeeklySpecial?category=" +
        category +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }

  getUserDetail(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "UserDetail?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getGroceriesGroup(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getGroup?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  // getGroceriesSubGroup(groupname: string, CardNo: string, header: string,Password: string ,pageno :string) : Observable<MyDataType[]>{

  getGroceriesSubGroup(
    groupname: string,
    CardNo: string,
    header: string,
    Password: string,
    pageno: string
  ) {
    this.films = this.http.get(
      this.url +
        "getSubGroup?group=" +
        groupname +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }

  getProducts(
    subgrp: string,
    CardNo: string,
    header: string,
    Password: string,
    pageno: string
  ) {
    this.films = this.http.get(
      this.url +
        "getSubGroupProduct?subgroup=" +
        subgrp +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    console.log(
      "~~~getProducts~~~~" +
        this.url +
        "getSubGroupProduct?subgroup=" +
        subgrp +
        "&cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Pageno=" +
        pageno
    );
    return this.films;
  }

  getGenratePassword(CardNo: string) {
    this.films = this.http.get(this.url + "GeneratePassword?Cardno=" + CardNo);
    return this.films;
  }

  getSearch(
    CardNo: string,
    header: string,
    searchkey: string,
    Password: string
  ) {
    searchkey = encodeURIComponent(searchkey);

    this.films = this.http.get(
      this.url +
        "getSearch?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&searchkey=" +
        searchkey +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getBarcodeSearch(
    CardNo: string,
    header: string,
    searchkey: string,
    Password: string
  ) {
    searchkey = encodeURIComponent(searchkey);
    this.films = this.http.get(
      this.url +
        "getBarcodeSearch?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&searchkey=" +
        searchkey +
        "&Password=" +
        Password
    );
    return this.films;
  }

  addItemInCart(
    CardNo: string,
    header: string,
    internalkey: string,
    cardWishType: string,
    qty: number,
    packQuantity: string,
    Password: string,
    Price:string 
  ) {
    this.films = this.http.get(
      this.url +
        "addItemInCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&internalkey=" +
        internalkey +
        "&CartwishType=" +
        cardWishType +
        "&quantity=" +
        qty +
        "&packQuantity=" +
        packQuantity +
        "&Password=" +
        Password +
        "&Price=" +
        Price
    );
    console.log(
      "~~by additem in card~~~" +
      this.url +
      "addItemInCart?Cardno=" +
      CardNo +
      "&Header=" +
      header +
      "&internalkey=" +
      internalkey +
      "&CartwishType=" +
      cardWishType +
      "&quantity=" +
      qty +
      "&packQuantity=" +
      packQuantity +
      "&Password=" +
      Password +
      "&Price=" +
      Price
    );
    return this.films;
  }

  getAllItemInCart(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "fetchAllCartItem?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  deleteItemFromCart(
    CardNo: string,
    header: string,
    id: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteItemFromCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&id=" +
        id +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getAllNotification(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "fetchAllNotification?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  updateCartProduct(
    CardNo: string,
    header: string,
    cartid: string,
    quantity: number,
    pack: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "updateCartProduct?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&cartid=" +
        cartid +
        "&quantity=" +
        quantity +
        "&pack=" +
        pack +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getKgQuantity() {
    this.films = this.http.get(this.url + "getKgQty");
    return this.films;
  }

  getAllOrderHistory(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getOrderHistory?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getPlaceOrder(CardNo: string, header: string, Password: string) {
    // CardNo= encodeURIComponent(CardNo);
    this.films = this.http.get(
      this.url +
        "getPlaceOrder?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getAllOffers(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getAllOffers?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    return this.films;
  }

  changeIsAlternative(
    CardNo: string,
    header: string,
    Password: string,
    isAlternative: string,
    cartitemid: string
  ) {
    this.films = this.http.get(
      this.url +
        "ChangeIsAlternative?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&isAlternative=" +
        isAlternative +
        "&cartitemid=" +
        cartitemid
    );
    return this.films;
  }

  changeIsAlternativeByCardNo(
    CardNo: string,
    header: string,
    Password: string,
    isAlternative: string
  ) {
    this.films = this.http.get(
      this.url +
        "ChangeIsAlternativeByCardNo?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&isAlternative=" +
        isAlternative
    );
    return this.films;
  }

  addItemInWishList(
    CardNo: string,
    header: string,
    internalkey: string,
    cardWishType: string,
    qty: number,
    packQuantity: string,
    Password: string,
    Price:number
  ) {
    this.films = this.http.get(
      this.url +
        "addItemInCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&internalkey=" +
        internalkey +
        "&CartwishType=" +
        cardWishType +
        "&quantity=" +
        qty +
        "&packQuantity=" +
        packQuantity +
        "&Password=" +
        Password +
        "&Price=" +
        Price
    );
  
    return this.films;

  }


  getAllItemInWishList(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "fetchAllWishListItem?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  deleteItemFromWishList(
    CardNo: string,
    header: string,
    id: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteItemFromWishList?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&id=" +
        id +
        "&Password=" +
        Password
    );
    return this.films;
  }

  moveItemsToCart(
    CardNo: string,
    header: string,
    Password: string,
    ids: string
  ) {
    this.films = this.http.get(
      this.url +
        "moveItemWishlistToCart?Cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&ids=" +
        ids +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getHomePageImage(
    CardNo: string,
    header: string,
    Password: string,
    Position: string
  ) {
    this.films = this.http.get(
      this.url +
        "getMainPageImgDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Position=" +
        Position
    );
    return this.films;
  }

  getHomePageDynamicData(CardNo: string, header: string, Password: string) {
    ///, ContentType: string
    this.films = this.http.get(
      this.url +
        "DynamicTemplate?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    ); // +"&ContentType="+ContentType);
    return this.films;
  }

  getHomePageSubgroup(
    CardNo: string,
    header: string,
    Password: string,
    SubGroupCategory: string
  ) {
    console.log("~~SubGroupCategory~~~~" + SubGroupCategory);
    this.films = this.http.get(
      this.url +
        "getSubGroupCategory?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&Category=" +
        SubGroupCategory
    );
    return this.films;
  }

  getSecondPageImage(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getSecondPageImgDynamic?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getSingleProduct(
    CardNo: string,
    header: string,
    Password: string,
    productkey: string,
   
  ) {
    this.films = this.http.get(
      this.url +
        "getSingleProduct?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&productkey=" +
        productkey 
    );
    return this.films;
  }

  getRegistration(
    firstname: string,
    lastname: string,
    address: string,
    telephone: string,
    mobile: string,
    dob: string,
    email: string,
    strImage: string,
    Header: string
  ) {
    this.films = this.http.get(
      this.url +
        "getRegistrationImage?Header=" +
        Header +
        "&Firstname=" +
        firstname +
        "&LastName=" +
        lastname +
        "&Address=" +
        address +
        "&Telephone=" +
        telephone +
        "&Mobile=" +
        mobile +
        "&DOB=" +
        dob +
        "&Email=" +
        email +
        "&image=" +
        strImage 
    );

    return this.films;
  }

  SubmitFeedbackWithoutImage(
    subject: string,
    description: string,
    cardno: string,
    header: string
  ) {
    // post method  with header and parameter
    let postData = new FormData();
    postData.append("Subject", subject);
    postData.append("Description", description);
    postData.append("Cardno", cardno);
    postData.append("Header", header);

    this.login = this.http.post(this.url + "submitFeedback", postData, {
      headers: {
        // 'content':"application/json",
        // 'content-type':"application/x-www-form-urlencoded",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
        // 'Accept': 'application/json',
        // 'content-type': 'application/json',
        // 'Doittoken': "TVRJek5EVWhRQ01r"
      },
    });
    return this.login;
  }

  getGiftCard(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "getGiftCardByCardNo?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  deleteGiftCard(
    CardNo: string,
    header: string,
    Password: string,
    Gitcardid: string
  ) {
    this.films = this.http.get(
      this.url +
        "deleteGiftCard?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&giftcardid=" +
        Gitcardid
    );
    return this.films;
  }

  sendGiftCard(
    CardNo: string,
    header: string,
    Password: string,
    Gitcardid: string,
    ReciverCardNo: string
  ) {
    this.films = this.http.get(
      this.url +
        "sendGiftCard?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        +"&giftcardid=" +
        Gitcardid +
        "&recieverCardno=" +
        ReciverCardNo
    );
    return this.films;
  }

  getTop10UserWishlist(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    console.log(
      "~~~~~~getTop10UserWishlist~~~~~~~" +
        this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    console.log(
      "~~~~~~getTop10UserWishlist~~~~~~~" +
        this.url +
        "Top10UserWishlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );

    return this.films;
  }

  getTop10UserOrderedlist(CardNo: string, header: string, Password: string) {
    this.films = this.http.get(
      this.url +
        "Top10UserOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getTop10UserProcessedOrderedlist(
    CardNo: string,
    header: string,
    Password: string
  ) {
    this.films = this.http.get(
      this.url +
        "Top10UserProcessedOrderedlist?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password
    );
    return this.films;
  }

  getSingleProductByInternalKey(
    CardNo: string,
    header: string,
    Password: string,
    productkey: string,
  ) {
    this.films = this.http.get(
      this.url +
        "getSingleProductWithInternalKey?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&productkey=" +
        productkey 
    );

    console.log(
      "~~by internal key~~~" +
        this.url +
        "getSingleProductWithInternalKey?cardno=" +
        CardNo +
        "&Header=" +
        header +
        "&Password=" +
        Password +
        "&productkey=" +
        productkey
    );
    return this.films;
  }

  // ====================================================/All api with security header==================================================================

  // ======================================================post api====================================================

  // addItemInCartPost(CardNo: string, header: string, internalkey: string, cardWishType: string,
  //   qty: string, packQuantity: string, Password: string) {          // post method  with header and parameter

  //   let postData = new FormData();
  //   postData.append('cardno', CardNo);
  //   postData.append('Header', header);
  //   postData.append('Password', Password);
  //   postData.append('internalkey', internalkey);
  //   postData.append('CartwishType', cardWishType);
  //   postData.append('packQuantity', packQuantity);
  //   postData.append('quantity', qty);

  //   this.login = this.http.post(this.url + 'addItemInCart', postData,
  //     {
  //       headers: {
  //         'content':"application/json",
  //         'content-type':"application/x-www-form-urlencoded",
  //         // 'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
  //         'Accept': 'application/json',
  //         // 'content-type': 'application/json',
  //         // 'Doittoken': "TVRJek5EVWhRQ01r"
  //       }
  //     });
  //   return this.login;
  // }

  // getPlaceOrderPost(CardNo: string, header: string, Password: string) {          // post method  with header and parameter
  //   let postData = new FormData();
  //   postData.append('cardno', CardNo);
  //   postData.append('Header', header);
  //   postData.append('Password', Password);

  //   this.login = this.http.post(this.url + 'getPlaceOrder', postData,
  //     {
  //       headers: {
  //         'content':"application/json",
  //         'content-type':"application/x-www-form-urlencoded",
  //         // 'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
  //         'Accept': 'application/json',
  //         // 'content-type': 'application/json',
  //         // 'Doittoken': "TVRJek5EVWhRQ01r"
  //       }
  //     });
  //   return this.login;
  // }

  // getAllItemInCartPost(CardNo: string, header: string, Password: string) {          // post method  with header and parameter
  //   let postData = new FormData();
  //   postData.append('cardno', CardNo);
  //   postData.append('Header', header);
  //   postData.append('Password', Password);

  //   this.login = this.http.post(this.url + 'fetchAllCartItem', postData,
  //     {
  //       headers: {
  //         'content':"application/json",
  //         'content-type':"application/x-www-form-urlencoded",
  //         // 'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
  //         'Accept': 'application/json',
  //         // 'content-type': 'application/json',
  //         // 'Doittoken': "TVRJek5EVWhRQ01r"
  //       }
  //     });
  //   return this.login;
  // }

  // ===================================================/ post api========================================================

  // ====================================================Storage getter and setter================================================================

  setWeeeklyOfferHeadingNav(FirstHeadingWeekly: string) {
    this.FirstHeadingWeekly = FirstHeadingWeekly;
  }

  getWeeeklyOfferHeadingNav() {
    return this.FirstHeadingWeekly;
  }

  setStorageCardNo(loginCardNo: string) {
    this.LoginCardNo = loginCardNo;
  }

  getStorageCardNo() {
    return this.LoginCardNo;
  }

  setStoragePassword(loginPassword: string) {
    this.LoginPassword = loginPassword;
  }

  getStoragePassword() {
    return this.LoginPassword;
  }

  setStorageHeader(loginheader: string) {
    this.LoginHeader = loginheader;
  }

  getStorageHeader() {
    return this.LoginHeader;
  }

  setIsGuest(IsGuest: any) {
    this.isGuest = IsGuest;
  }

  getIsGuest() {
    return this.isGuest;
  }

  getCardCount() {
    return this.CartCount;
  }

  setCardCount(cartCount: any) {
    this.CartCount = cartCount;
  }

  setCurrency(currency: string) {
    this.Currency = currency;
  }

  getCurrency() {
    return this.Currency;
  }

  setFirebaseToken(token: string) {
    console.log("~~~~~~~~~~~~~~token~refresh~~~~~~~~~~~~~~~~" + token);
    this.FirebaseToken = token;
  }

  getFirebaseToken() {
    return this.FirebaseToken;
  }

  // ==================================================== / Storage getter and setter=======================================================================

  // ====================================================Web Notification=======================================================================

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.serviceWorker.ready.then(
        (registration) => {
          // Don't crash an error if messaging not supported
          if (!firebase.messaging.isSupported()) {
            resolve();
            return;
          }

          const messaging = firebase.messaging();

          // Register the Service Worker
          messaging.useServiceWorker(registration);

          // Initialize your VAPI key
          messaging.usePublicVapidKey(environment.firebase.vapidKey);

          // Optional and not covered in the article
          // Listen to messages when your app is in the foreground
          messaging.onMessage((payload) => {
            console.log(payload);
          });
          // Optional and not covered in the article
          // Handle token refresh
          messaging.onTokenRefresh(() => {
            messaging
              .getToken()
              .then((refreshedToken: string) => {
                this.setFirebaseToken(refreshedToken);
                console.log(refreshedToken);
              })
              .catch((err) => {
                console.error(err);
              });
          });

          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  requestPermission(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!Notification) {
        resolve();
        return;
      }
      if (!firebase.messaging.isSupported()) {
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();

        const token: string = await messaging.getToken();

        this.setFirebaseToken(token);

        console.log("User notifications token:", token);
      } catch (err) {
        // No notifications granted
      }

      resolve();
    });
  }

  // ==================================================== / Web Notification=======================================================================

  getLoginPostLive(CardNo: string, pass: string) {
    // post method  with header and parameter
    let postData = new FormData();
    postData.append("CardNo", CardNo);
    postData.append("Password", pass);
    postData.append("DeviceId", "25252525");
    postData.append("DeviceType", "android");

    this.login = this.http.post(
      "http://dashboard.doit.aw:8081/doit_copy/user2/login",
      postData,
      {
        headers: {
          // 'content':"application/json",
          // 'content-type':"application/x-www-form-urlencoded",
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          // 'Accept': 'application/json',
          // 'content-type': 'application/json',
          Doittoken: "TVRJek5EVWhRQ01r",
        },
      }
    );
    return this.login;
  }

  getLoginGet() {
    // get method  with parameter

    // https://africanloving.com/africaloving_api/index.php/?fun=login&username=shivalitest&pass=12

    // Initialize Params Object
    let params = new HttpParams();
    // Begin assigning parameters
    // params = params.append('username', "shivalitest");
    // params = params.append('pass', "12");
    // params = params.append('secondParameter', parameters.valueTwo);
    // Make the API call using the new parameters.
    this.login = this.http.get(
      "http://localhost:57350/WebForm1.aspx?login=hello",
      { params: params }
    );
    return this.login;
  }

  startMyThread() {
    return new Promise((resolve, reject) => {
      //pretend a long-running task
      // setTimeout(() => { resolve(true); }, 1000);
      setTimeout(() => {
        resolve(true);
      }, 10);
    });
  }
}

export class Top10Data {
  public id: number;
  public Code: string;
  public internalkey: string;
  public qty: number;
  public isAlternative: string;
  public Pack: string;
  public cardno: string;
  public Carwishtype: string;
  public StockGroup: string;
  public offer_price: string;
  public SellUnit: string;
  public Image: string;
  public Description: string;
  public subgroup: string;
  public CreationDt: string;

  // "id": 17618,
  // "internalkey": "990613",
  // "qty": 1,
  // "isAlternative": true,
  // "Pack": "null",
  // "cardno": "24265",
  // "Carwishtype": 1,
  // "StockGroup": "G1400",
  // "offer_price": null,
  // "SellUnit": "EA",
  // "Image":
  // "Description": "EE YOGURT FAT FREE PLAIN 32oz",
  // "subgroup": "G1406",
  // "RetailPrice": 6.99,
  // "CreationDt
}

export class MyDataType {
  public id: number;
  public Groupid: string;
  public subgroup: string;
  public ImageID: string;
  public ImageName: string;
  public Image: string;

  //id":5,"Groupid":"G100","subgroup":"Packed Soups","ImageID":"G1001","ImageName":"G1001","Image"
  // {"subGroup":[{"id":40,"Groupid":"G1400","subgroup":"Eggs","ImageID":"G1401","ImageName":"G1401","Image
}
export class CatDataType {
  public id: number;
  public Group: string;
  public ImageID: string;
  public ImageName: string;
  public Image: string;
}
